import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CardActionArea from '@material-ui/core/CardActionArea'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2.5%',
  },
  cardMedia: {
    // This is useful if the background image technique is used
    // paddingTop: '56.25%', // 16:9
    height: '200px',
  },
  cardContent: {
    flexGrow: 1,
  },
}))

const EventCard = ({ event }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query MyQuery {
      file(relativePath: { eq: "music-folder.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardActionArea component={Link} to={event.slug}>
          <CardMedia
            component={() => (
              <Img
                className={classes.cardMedia}
                fluid={
                  event.image
                    ? event.image.childImageSharp.fluid
                    : data.file.childImageSharp.fluid
                }
              />
            )}
            title="Image title"
          />
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" component="h2">
              {event.title}
            </Typography>
            <Typography gutterBottom variant="body2">
              {event.location}
            </Typography>
            <Typography>{event.excerpt}</Typography>
          </CardContent>
          <CardActions>
            <Link to={event.slug} style={{ textDecoration: 'none' }}>
              <Button size="large" color="primary">
                Läs mer
              </Button>
            </Link>
          </CardActions>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

EventCard.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.object,
  }).isRequired,
}

export default EventCard
