import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import YouTubeIcon from '@material-ui/icons/YouTube'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import { graphql } from 'gatsby'

import Content, { HTMLContent } from '../components/content'
import EventCard from '../components/EventCard'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import ForwardOutboundLink from '../components/ForwardOutboundLink'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  section: {
    padding: theme.spacing(8, 0, 6),
  },
  logo: {
    maxWidth: '75%',
    margin: 'auto',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  contentImage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[4],
  },
  membersGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  singerName: {
    marginBottom: theme.spacing(0.5),
  },
  socialLink: {
    fontSize: theme.typography.fontSize * 4,
  },
  facebook: {
    color: '#4267B2',
  },
  youtube: {
    color: '#FF0000',
  },
  instagram: {
    color: '#cd486b',
  },
}))

export const IndexPageTemplate = ({
  heroTitle,
  heroSubtitle,
  applyVisible,
  applyHeading,
  applyImage,
  applyContent,
  extraInfoVisible,
  extraInfoHeading,
  extraInfoContent,
  aboutContent,
  aboutImage,
  conductorHeading,
  conductorContent,
  conductorImage,
  events,
  voiceList,
  logoImage,
  contentComponent,
}) => {
  const classes = useStyles()
  const ContentComponent = contentComponent || Content

  return (
    <React.Fragment>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              variant="h1"
              align="center"
              color="textPrimary"
              gutterBottom
              className="visually-hidden"
            >
              {heroTitle}
            </Typography>
            <PreviewCompatibleImage
              imageInfo={{ image: logoImage }}
              className={classes.logo}
            />
            <Typography
              variant="h5"
              component="p"
              align="center"
              color="textSecondary"
              paragraph
            >
              {heroSubtitle}
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <IconButton
                    variant="contained"
                    className={`${classes.socialLink} ${classes.facebook}`}
                    component={ForwardOutboundLink}
                    href="https://www.facebook.com/pg/veranovokalensemble"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="visually-hidden">Besök oss på Facebook</p>
                    <FacebookIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    variant="contained"
                    className={`${classes.socialLink} ${classes.youtube}`}
                    component={ForwardOutboundLink}
                    href="https://www.youtube.com/channel/UCnWc_Bpb_0DE4B-lzU6-7Ag"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="visually-hidden">Besök oss på YouTube</p>
                    <YouTubeIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    variant="contained"
                    className={`${classes.socialLink} ${classes.instagram}`}
                    component={ForwardOutboundLink}
                    href="https://www.instagram.com/veranovokalensemble/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="visually-hidden">Besök oss på Instagram</p>
                    <InstagramIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        {/* End hero unit */}
        <Container maxWidth="sm">
          <Divider variant="middle" />
        </Container>
        {/* Apply section */}
        {applyVisible && (
          <>
            <div className={classes.section}>
              <Container maxWidth="sm">
                <Typography
                  variant="h2"
                  align="center"
                  gutterBottom
                  id="sok-koren"
                >
                  {applyHeading}
                </Typography>
                <PreviewCompatibleImage
                  imageInfo={{ image: applyImage }}
                  className={classes.contentImage}
                />
                <Typography variant="body1" component="div">
                  <ContentComponent content={applyContent} />
                </Typography>
              </Container>
            </div>
            <Container maxWidth="sm">
              <Divider variant="middle" />
            </Container>
          </>
        )}
        {/* End apply section */}
        {/* Extra info section */}
        {extraInfoVisible && (
          <>
            <div className={classes.section}>
              <Container maxWidth="sm">
                <Typography
                  variant="h3"
                  align="center"
                  gutterBottom
                  id="extra-info"
                >
                  {extraInfoHeading}
                </Typography>
                <Typography variant="body1" component="div">
                  <ContentComponent content={extraInfoContent} />
                </Typography>
              </Container>
            </div>
            <Container maxWidth="sm">
              <Divider variant="middle" />
            </Container>
          </>
        )}
        {/* End extra info section */}
        {/* About section */}
        <div className={classes.section}>
          <Container maxWidth="sm">
            <Typography variant="h2" align="center" gutterBottom id="om-koren">
              Om kören
            </Typography>
            <PreviewCompatibleImage
              imageInfo={{ image: aboutImage }}
              className={classes.contentImage}
            />
            <Typography variant="body1" component="div">
              <ContentComponent content={aboutContent} />
            </Typography>
          </Container>
        </div>
        {/* End about section */}
        {events && events.length > 0 && (
          <>
            <Container maxWidth="sm">
              <Divider variant="middle" />
            </Container>
            <div className={classes.section}>
              <Container maxWidth="md">
                <Typography
                  variant="h2"
                  align="center"
                  gutterBottom
                  id="konserter"
                >
                  Kommande konserter
                </Typography>
              </Container>
              <Container className={classes.cardGrid} maxWidth="md">
                <Grid container spacing={4}>
                  {events &&
                    events.map((event) => (
                      <EventCard key={event.id} event={event} />
                    ))}
                </Grid>
              </Container>
            </div>
          </>
        )}
        {conductorContent && (
          <>
            <Container maxWidth="sm">
              <Divider variant="middle" />
            </Container>
            <div className={classes.section}>
              <Container maxWidth="sm">
                <Typography
                  variant="h2"
                  align="center"
                  gutterBottom
                  id="dirigent"
                >
                  {conductorHeading}
                </Typography>
                <PreviewCompatibleImage
                  imageInfo={{ image: conductorImage }}
                  className={classes.contentImage}
                />
                <Typography variant="body1" component="div">
                  <ContentComponent content={conductorContent} />
                </Typography>
              </Container>
            </div>
          </>
        )}
        {voiceList && (
          <>
            <Container maxWidth="sm">
              <Divider variant="middle" />
            </Container>
            <div className={classes.section}>
              <Container maxWidth="md">
                <Typography
                  variant="h2"
                  align="center"
                  gutterBottom
                  id="sangare"
                >
                  Våra sångare
                </Typography>
              </Container>
              <Container className={classes.membersGrid} maxWidth="md">
                <Grid container spacing={4}>
                  {voiceList &&
                    voiceList.map(({ label, singers }) => (
                      <Grid key={label} item md={3} sm={6} xs={12}>
                        <Typography variant="h6" align="center" gutterBottom>
                          {label}
                        </Typography>
                        {singers &&
                          singers.map((singer) => (
                            <Typography
                              key={singer}
                              variant="body1"
                              align="center"
                              className={classes.singerName}
                            >
                              {singer}
                            </Typography>
                          ))}
                      </Grid>
                    ))}
                </Grid>
              </Container>
            </div>
          </>
        )}
      </main>
    </React.Fragment>
  )
}

IndexPageTemplate.propTypes = {
  heroTitle: PropTypes.string.isRequired,
  heroSubtitle: PropTypes.string.isRequired,
  applyVisible: PropTypes.bool.isRequired,
  applyHeading: PropTypes.string.isRequired,
  applyImage: PropTypes.object.isRequired,
  applyContent: PropTypes.string.isRequired,
  extraInfoVisible: PropTypes.bool.isRequired,
  extraInfoHeading: PropTypes.string.isRequired,
  extraInfoContent: PropTypes.string.isRequired,
  aboutContent: PropTypes.string.isRequired,
  aboutImage: PropTypes.object.isRequired,
  conductorHeading: PropTypes.string.isRequired,
  conductorContent: PropTypes.string.isRequired,
  conductorImage: PropTypes.object.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  voiceList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      singers: PropTypes.array.isRequired,
    })
  ),
  logoImage: PropTypes.object.isRequired,
  contentComponent: PropTypes.func,
}

const IndexPage = ({ data }) => {
  const {
    heroTitle,
    heroSubtitle,
    logoImage,
    applyVisible,
    applyHeading,
    applyImage,
    extraInfoVisible,
    extraInfoHeading,
    conductorHeading,
    conductorImage,
    aboutImage,
  } = data.markdownRemark.frontmatter

  const events = data.allMarkdownRemark.edges.map((edge) => ({
    ...edge.node.frontmatter,
    excerpt: edge.node.excerpt,
    id: edge.node.id,
    slug: edge.node.fields.slug,
  }))

  const { applyContent, extraInfoContent, aboutContent, conductorContent } =
    data.markdownRemark.fields.frontmattermd

  const { voiceList } = data.singersYaml.fields

  return (
    <IndexPageTemplate
      heroTitle={heroTitle}
      logoImage={logoImage}
      heroSubtitle={heroSubtitle}
      applyVisible={applyVisible}
      applyHeading={applyHeading}
      applyImage={applyImage}
      applyContent={applyContent.html}
      extraInfoVisible={extraInfoVisible}
      extraInfoHeading={extraInfoHeading}
      extraInfoContent={extraInfoContent.html}
      aboutContent={aboutContent.html}
      aboutImage={aboutImage}
      conductorHeading={conductorHeading}
      conductorContent={conductorContent.html}
      conductorImage={conductorImage}
      events={events}
      voiceList={voiceList}
      contentComponent={HTMLContent}
    />
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query PageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        heroTitle
        heroSubtitle
        logoImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        applyVisible
        applyHeading
        applyImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        extraInfoVisible
        extraInfoHeading
        aboutImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        conductorHeading
        conductorImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        frontmattermd {
          applyContent {
            html
          }
          extraInfoContent {
            html
          }
          aboutContent {
            html
          }
          conductorContent {
            html
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "event-page" } }
        isFuture: { eq: true }
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 120)
          frontmatter {
            price
            location
            date
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    singersYaml(year: { eq: 2024 }) {
      fields {
        voiceList {
          label
          singers
        }
      }
    }
  }
`
